import { firstByClass, byClass, remove } from 'OK/utils/dom';
import { isElementInViewport, ajax } from 'OK/utils/utils';
import AbstractEntitySuggest from 'OK/entitySuggest/AbstractEntitySuggest';
import LogSeen from 'OK/LogSeen';
import throttle from 'OK/utils/throttle';

var REQUEST_NEW_ITEMS_THRESHOLD = 2;
var SLIDER_ITEM_CLASSNAME = "uslider_i";

export default class FriendshipFeedPortlet {
    /**
     * @param {boolean} isEmpty
     */
    changeState(isEmpty) {
        this.hook.classList.toggle('__empty', isEmpty);
    }

    logSlidesSeen(offset, count) {
        var slides = this.slider.getElementsByClassName("js-entity-item");
        if (slides && slides.length > 0) {

            var ids = [];
            var positions = [];
            var topics = [];
            var explanations = [];

            for (var i = offset; i < offset + count && slides[i]; i++) {
                var id = parseInt(slides[i].getAttribute("data-entity-id"), 10) || null;
                if (id !== null) {
                    ids.push(id);
                    positions.push(i);

                    var topicId = parseInt(slides[i].getAttribute("data-topic-id"), 10) || null;
                    topics.push(String(topicId));

                    var dataExplanation = slides[i].getAttribute("data-explanation") || null;
                    explanations.push(dataExplanation);
                }
            }

            if (ids.length > 0) {
                this.seenParams.data['user_ids'] = ids.join(',');
                this.seenParams.data['positions'] = positions.join(',');
                this.seenParams.data['topics'] = topics.join(',');
                this.seenParams.data['explanations'] = explanations.join(',');
                LogSeen.logSuccess(this.seenParams.type, JSON.stringify(this.seenParams.data));
            }
        }
    }

    logSlidesIfInViewPort() {
        try {
            var visibleSlides = this.slider.hasAttribute("data-visibleSlides");

            if (visibleSlides && isElementInViewport(this.slider)) {
                this.logSlidesSeen(this.sliderOffset, parseInt(this.slider.getAttribute("data-visibleSlides"), 10) || 0);
                this.removeLogSeenOnWindowScrollListeners();
            }
        } catch (ex) {
        }
    }

    removeLogSeenOnWindowScrollListeners() {
        window.removeEventListener("scroll", this.logSeenOnWinowScroll);
        window.removeEventListener("keydown", this.logSeenOnWinowScroll);
    }

    activate(element) {
        this.marker = element.getAttribute('data-slider-marker') || null;
        this.loadActivityId = element.getAttribute('data-load-aid') || 'Feed_PossibleFriends_LoadFriendChunk';
        this.sliderOffset = 0;
        this.lastRemovedEntityIndex = null;

        /** @type {HTMLElement}*/
        this.hook = element;
        /** @type {HTMLElement}*/
        this.slider = firstByClass(element, 'uslider');
        /** @type {HTMLElement}*/
        this.entitySuggest = firstByClass(element, 'js-entity-suggest');
        this.type = this.entitySuggest.getAttribute('data-type');
        /** @type {AbstractEntitySuggest}*/
        this.entitySuggestInstance = null;
        this.suggestFriendsPortlets = AbstractEntitySuggest.prototype.getPortlets(this.type);
        this.mode = this.entitySuggest.getAttribute('data-mode');
        this.userId = this.entitySuggest.getAttribute('data-user-id');
        this.isLargeCard = this.entitySuggest.getAttribute('data-large-card') === 'true';

        var seenParams = element.getAttribute('data-seen-params');
        if (seenParams) {
            try {
                this.seenParams = JSON.parse(seenParams);
            } catch (ex) {
                this.seenParams = null;
            }
        }

        this.getNewSliderItems = function (e) {
            if (this.seenParams) {
                this.logSeenOnSlide(e);
            }

            if (this.suggestFriendsPortlets && this.entitySuggest) {
                this.entitySuggestId = parseInt(this.entitySuggest.getAttribute('data-entity-suggest-id'), 10);
                this.entitySuggestInstance = this.suggestFriendsPortlets[this.entitySuggestId];
            }
            if (this.entitySuggestInstance && this.marker && ((e.detail.totalSlides - e.detail.offset) < (e.detail.visibleSlides + REQUEST_NEW_ITEMS_THRESHOLD))) {
                ajax({
                    url: AbstractEntitySuggest.urlConfig[this.type],
                    data: {
                        'st.modes': this.mode,
                        list: Object.keys(AbstractEntitySuggest.prototype.getCommonEntities(this.type)).join(";"),
                        slideSequence: true,
                        'st._aid': this.loadActivityId,
                        marker: this.marker,
                        largeCard: this.isLargeCard,
                        'st.fid': this.userId
                    }
                }).done(function (data, text, xhr) {
                    var blocks = data.split(OK.navigation.SPLITER);
                    var resultString = "";

                    this.marker = xhr.getResponseHeader('marker'); // маркер пагинации для подгрузки

                    blocks.forEach(function (blockHTMLString) {
                        if (blockHTMLString) {
                            resultString = resultString + '<div class="' + SLIDER_ITEM_CLASSNAME + '">' + blockHTMLString + '</div>';
                        }
                    });

                    this.entitySuggestInstance.append(resultString);

                    var isEmpty = !e.detail.totalSlides && !data;
                    this.changeState(isEmpty);
                }.bind(this));
            }
            if (!this.marker && !e.detail.totalSlides) {
                this.changeState(true);
            }
        }.bind(this);

        this.changeSliderItemsCount = function (e) {
            if (e.detail.type === 'remove') {
                var sliderItems = byClass(this.slider, SLIDER_ITEM_CLASSNAME);
                this.lastRemovedEntityIndex = e.detail.index;

                if (this.lastRemovedEntityIndex !== undefined) {
                    remove(sliderItems[this.lastRemovedEntityIndex]);

                    this.slider.dispatchEvent(
                        new CustomEvent("uSliderUpdateCount", {
                            detail: {
                                type: 'remove'
                            }
                        })
                    );
                } else {
                    this.lastRemovedEntityIndex = null;
                }
            }
            if (e.detail.type === 'append') {
                this.slider.dispatchEvent(
                    new CustomEvent("uSliderUpdateCount", {
                        detail: {
                            type: 'append'
                        }
                    })
                );
            }

            if (e.detail.type === 'replace') {
                if (this.seenParams) {
                    //Если элемент был удален из другого портлета, то новый может быть и не виден в слайдере
                    var visibleSlides = parseInt(this.slider.getAttribute("data-visibleSlides"), 10) || 0;
                    if (this.sliderOffset <= e.detail.index && this.sliderOffset + visibleSlides - 1 >= e.detail.index) {
                        this.logSlidesSeen(e.detail.index, 1);
                    }
                }
            }
        }.bind(this);

        if (this.seenParams) {
            this.logSeenOnWinowScroll = throttle(150, function (e) {
                if (!e.isTrigger) {
                    this.logSlidesIfInViewPort();
                }
            }.bind(this));

            this.logSeenOnSlide = function (e) {
                if ((e.detail.action === "slide") ||
                    ((e.detail.action === "remove") &&
                        //Если элемент был удален из другого портлета, то новый может быть и не виден в слайдере
                        (this.sliderOffset <= this.lastRemovedEntityIndex && this.sliderOffset + e.detail.visibleSlides >= this.lastRemovedEntityIndex))) {
                    var offsetDiff = e.detail.offset - this.sliderOffset;

                    //Если подскроллились карды справа, а не слева, то offset не поменялся
                    if (offsetDiff === 0) {
                        if (this.lastRemovedEntityIndex !== null) {
                            this.logSlidesSeen(this.lastRemovedEntityIndex, 1);
                            this.lastRemovedEntityIndex = null;
                        }
                    } else {
                        var count = Math.abs(offsetDiff);
                        var startOffset = this.sliderOffset + offsetDiff;
                        this.sliderOffset = e.detail.offset;
                        this.logSlidesSeen(startOffset, count);
                    }
                }
            }.bind(this);

            this.loadDataVisibleSlidesAttribute = function () {
                this.logSlidesIfInViewPort();
            }.bind(this);

            window.addEventListener("scroll", this.logSeenOnWinowScroll);
            window.addEventListener("keydown", this.logSeenOnWinowScroll);

            this.logSlidesIfInViewPort();

            // Сделано из-за того, что "data-visibleSlides" в uslider.js не успевает посчитаться
            this.slider.addEventListener('dataVisibleSlidesLoadOrChanged', this.loadDataVisibleSlidesAttribute);
        }

        // Запросить новые элементы
        this.slider.addEventListener('uSliderAction', this.getNewSliderItems);

        // Пересчитать содержимое слайдера
        this.entitySuggest.addEventListener('entitySuggestUpdateCount', this.changeSliderItemsCount);
    }

    deactivate() {
        if (this.seenParams) {
            this.removeLogSeenOnWindowScrollListeners();
            this.slider.removeEventListener('dataVisibleSlidesLoadOrChanged', this.loadDataVisibleSlidesAttribute);
        }
        this.slider.removeEventListener('uSliderAction', this.getNewSliderItems);
        this.entitySuggest.removeEventListener('entitySuggestUpdateCount', this.changeSliderItemsCount);
    }
}
